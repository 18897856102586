@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .zoom {
    transform: scale(0.5) translateY(5%);
    opacity: 0;
    animation: zoom;
  }

  .shine-btn {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      bottom: -5px;
      width: 10px;
      transform: rotate(10deg);
      background: #fff;
      opacity: 0.4;
      left: -50px;
      animation: shineLarge 2s infinite;
    }
    &::after {
      content: '';
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -30px;
      width: 4px;
      transform: rotate(10deg);
      background: #fff;
      opacity: 0.4;
      animation: shineSmall 2s infinite;
    }
  }
  .bg-stripe-gradient {
    background: repeating-linear-gradient(
      -55deg,
      #8498ff,
      #8498ff 8px,
      #497ff7 8px,
      #497ff7 13px
    );
  }

  .disabled-btn {
    position: relative;
    overflow: hidden;
    opacity: 0.6;
    ::after {
      position: absolute;
      inset: 0;
      content: '';
      background-color: #fff;
    }
  }

  @keyframes shineLarge {
    0% {
      left: -50px;
      transition-property: left;
    }
    80% {
      left: 100%;
      transition-property: left;
    }
    100% {
      left: calc(100% + 80px);
      transition-property: left;
    }
  }
  @keyframes shineSmall {
    0% {
      left: -30px;
      transitin-property: left;
    }
    80% {
      left: calc(100% + 20px);
      transition-property: left;
    }
    100% {
      left: calc(100% + 100px);
      transition-property: left;
    }
  }

  @keyframes claimElevate {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: translateY(-1%);
    }
  }

  .claim-elevate {
    animation: claimElevate cubic-bezier(0.42, 0, 1, 1.2) 1.5s infinite;
  }

  @keyframes claimShadowElevate {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scaleX(0.97);
    }
  }

  .claim-shadow-elevate {
    animation: claimShadowElevate cubic-bezier(0.42, 0, 1, 1.2) 1.5s infinite;
  }

  @keyframes claimBounce {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: translateY(-15%);
    }
  }
  .claim-bounce {
    animation: claimBounce cubic-bezier(0.42, 0, 1, 1.2) 0.8s;
  }

  @keyframes claimPulse {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scaleX(0.9);
    }
  }
  .claim-pulse {
    animation: claimPulse cubic-bezier(0.42, 0, 1, 1.2) 0.8s;
  }

  .black-overlay {
    background: linear-gradient(to top, #000000, transparent 50%);
  }

  .green-overlay {
    background: linear-gradient(to top, #576a49, transparent 50%);
    z-index: 25;
  }
  .orange-overlay {
    background: linear-gradient(to top, #f07340, transparent 50%);
    z-index: 25;
  }
  .rummy-overlay {
    background: linear-gradient(to top, #b43024, transparent 50%);
    z-index: 25;
  }
  .d11-overlay {
    background: linear-gradient(to top, #ae0001, transparent 50%);
    z-index: 25;
  }
  .Neu-overlay {
    background: linear-gradient(to top, #150130, transparent 50%);
    z-index: 25;
  }

  @keyframes zoom {
    0%,
    100% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
  }

  .animate-zoom {
    animation: zoom ease-in 1s infinite;
  }

  .claim-reward-overlay {
    background: transparent
      linear-gradient(180deg, #faedc7 0%, #fffcf7 48%, #fbf1d3 100%) 0% 0%
      no-repeat padding-box;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    transform: translateY(-35px);
  }
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    border: 1px solid white;
    border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    color: unset;
  }
  .slick-dots li.slick-active button:before {
    color: unset;
    background: white;
  }

  .slick-dots li {
    width: 8px;
    height: 20px;
  }
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__dots {
  z-index: 1;
  bottom: 0.7rem;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embla__dot {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
}
.embla__dot:after {
  // background: white;
  border: 1px solid white;
  border-radius: 50%;
  width: 100%;
  height: 8px;
  content: '';
}
.embla__dot--selected:after {
  background: #ffffff;
}

.glass {
  box-shadow: 0px 0px 30px 0px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.35);
}

.sandboxMode {
  border-top: 5px solid #f9d328;
  width: 100%;
  position: fixed;
  z-index: 2;
  .contentRoot {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 0;
    .content {
      height: 0;
      padding: 10px 30px 20px 30px;
      display: flex;
      align-items: center;
      background-color: #f9d328;
      // padding: 0.5rem 2rem 1rem 2.5rem;
      // padding-top: 0.5rem;
      display: flex;
      justify-content: center;
      clip-path: polygon(0 0, 100% 0%, 85% 85%, 15% 85%);
    }
  }
}

.offer-card-gradient {
  background: linear-gradient(
    -86.8deg,
    rgba(48, 70, 188, 0) 1.17%,
    rgba(48, 70, 188, 0.16) 100%
  );
}
